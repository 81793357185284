import { inject, injectable } from "inversify";
import { RouterStore as RoutingStore, syncHistoryWithStore } from "mobx-react-router";
import { hashHistory } from "react-router";

import { container, lazyInject } from "./browser.container";
import { ChangePasswordStore } from "./components/ChangePassword/ChangePasswordStore";
import { CooperationAgreementDynamicFormStore } from "./components/CooperationAgreementDynamicFormDialog/CooperationAgreementDynamicFormStore";
import { CreditLineStore } from "./components/CreditLine/CreditLineStore";
import { EmailAttemptsStore } from "./components/EmailAttempts/EmailAttemptsStore";
import { NotificationsStore } from "./components/Notifications/NotificationsStore";
import { CountryGroupsStore } from "./domain/countryGroups/CountryGroupsStore";
import { DriverLayoutStore } from "./layouts/DriverLayout/DriverLayoutStore";
import { DriversCompanyLayoutStore } from "./layouts/DriversCompanyLayout/DriversCompanyLayoutStore";
import { LayoutStore } from "./layouts/Layout/LayoutStore";
import { ApiPartnerPageStore } from "./pages/ApiPartner/ApiPartnerPageStore";
import { ApiPartnersPageStore } from "./pages/ApiPartners/ApiPartnersPageStore";
import { AssignationsPageStore } from "./pages/Assignations/AssignationsPageStore";
import { BillingInformationPageStore } from "./pages/BillingInformation/BillingInformationPageStore";
import { BlacklistedDepartureDatesPageStore } from "./pages/BlacklistedDepartureDates/BlacklistedDepartureDatesPageStore";
import { CompanyDriversPageStore } from "./pages/CompanyDrivers/CompanyDriversPageStore";
import { CompletedTripsPageStore } from "./pages/CompletedTrips/CompletedTripsPageStore";
import { ContentMapPageStore } from "./pages/ContentMap/ContentMapPageStore";
import { CooperationAgreementPageStore } from "./pages/CooperationAgreement/CooperationAgreementPageStore";
import { CountriesPageStore } from "./pages/Countries/CountriesPageStore";
import { CountryPageStore } from "./pages/Country/CountryPageStore";
import { CurrenciesPageStore } from "./pages/Currencies/CurrenciesPageStore";
import { CustomerPageStore } from "./pages/Customer/CustomerPageStore";
import { CustomerFeedbacksForDriverPageStore } from "./pages/CustomerFeedbacksForDriver/CustomerFeedbacksForDriverPageStore";
import { CustomerFeedbacksForDriversCompanyPageStore } from "./pages/CustomerFeedbacksForDriversCompany/CustomerFeedbacksForDriversCompanyPageStore";
import { CustomersPageStore } from "./pages/Customers/CustomersPageStore";
import { CustomersFeedbacksPageStore } from "./pages/CustomersFeedbacks/CustomersFeedbacksPageStore";
import { DeclinedAndCancelledTripsPageStore } from "./pages/DeclinedAndCancelledTrips/DeclinedAndCancelledTripsPageStore";
import { DocumentTypesPageStore } from "./pages/DocumentTypes/DocumentTypesPageStore";
import { DriverAvailabilityPageStore } from "./pages/DriverAvailability/DriverAvailabilityPageStore";
import { DriverCountryRulesPageStore } from "./pages/DriverCountryRules/DriverCountryRulesPageStore";
import { DriverProfileStore } from "./pages/DriverProfile/DriverProfileStore";
import { DriversPageStore } from "./pages/Drivers/DriversPageStore";
import { DriversAssignationPageStore } from "./pages/DriversAssignation/DriversAssignationPageStore";
import { DriversAssignationsPageStore } from "./pages/DriversAssignations/DriversAssignationsPageStore";
import { DriversBalancesPageStore } from "./pages/DriversBalances/DriversBalancesPageStore";
import { DriversCompaniesPageStore } from "./pages/DriversCompanies/DriversCompaniesPageStore";
import { DriversCompanyStore } from "./pages/DriversCompany/DriversCompanyStore";
import { DriversCompanyDeclinedAndCancelledTripsPageStore } from "./pages/DriversCompanyDeclinedAndCancelledTrips/DriversCompanyDeclinedAndCancelledTripsPageStore";
import { DriversCompanyUpcomingTripsPageStore } from "./pages/DriversCompanyUpcomingTrips/DriversCompanyUpcomingTripsPageStore";
import { DriversFaqPageStore } from "./pages/DriversFaq/DriversFaqPageStore";
import { DriversMapPageStore } from "./pages/DriversMap/DriversMapPageStore";
import { DriversPayoutsPageStore } from "./pages/DriversPayouts/DriversPayoutsPageStore";
import { ExportsPageStore } from "./pages/Exports/ExportsPageStore";
import { FlexibleOffersPageStore } from "./pages/FlexibleOffers/FlexibleOffersPageStore";
import { HostAgenciesPageStore } from "./pages/HostAgencies/HostAgenciesPageStore";
import { HostAgencyPageStore } from "./pages/HostAgency/HostAgencyPageStore";
import { IndexPageStore } from "./pages/Index/IndexPageStore";
import { InvoicesPageStore } from "./pages/Invoices/InvoicesPageStore";
import { LanguagesPageStore } from "./pages/Languages/LanguagesPageStore";
import { LocationPageStore } from "./pages/Location/LocationPageStore";
import { PoolLocationPageStore } from "./pages/Location/PoolLocation/PoolLocationPageStore";
import { LocationsPageStore } from "./pages/Locations/LocationsPageStore";
import { LoginPageStore } from "./pages/Login/LoginPageStore";
import { NumberingTemplatesPageStore } from "./pages/NumberingTemplates/NumberingTemplatesPageStore";
import { OrderPageStore } from "./pages/Order/OrderPageStore";
import { OrdersPageStore } from "./pages/Orders/OrdersPageStore";
import { OrdersAddressCheckPageStore } from "./pages/OrdersCheck/OrdersAddressCheck/OrdersAddressCheckPageStore";
import { OrdersDepositCheckPageStore } from "./pages/OrdersCheck/OrdersDepositCheck/OrdersDepositCheckPageStore";
import { PartnerStore } from "./pages/Partner/PartnerStore";
import { PartnersStore } from "./pages/Partners/PartnersStore";
import { PasswordResetPageStore } from "./pages/PasswordReset/PasswordResetPageStore";
import { PaymentsPageStore } from "./pages/Payments/PaymentsPageStore";
import { PoolAvailabilityConfigPageStore } from "./pages/PoolAvailabilityConfig/PoolAvailabilityConfigPageStore";
import { PoolAvailabilityConfigsPageStore } from "./pages/PoolAvailabilityConfigs/PoolAvailabilityConfigsPageStore";
import { ReferralCodesPageStore } from "./pages/ReferralCodes/ReferralCodesPageStore";
import { RefundsPageStore } from "./pages/Refunds/RefundsPageStore";
import { RidePageStore } from "./pages/Ride/RidePageStore";
import { RidesPageStore } from "./pages/Rides/RidesPageStore";
import { LandingContentPageStore } from "./pages/Route/LandingContent/LandingContentPageStore";
import { LandingFAQsPageStore } from "./pages/Route/LandingFAQs/LandingFAQsPageStore";
import { RoutePageStore } from "./pages/Route/RoutePageStore";
import { RoutesPageStore } from "./pages/Routes/RoutesPageStore";
import { TagsPageStore } from "./pages/Tags/TagsPageStore";
import { TranslationsPageStore } from "./pages/Translations/TranslationsPageStore";
import { TravelAgentPageStore } from "./pages/TravelAgent/TravelAgentPageStore";
import { TravelAgentsPageStore } from "./pages/TravelAgents/TravelAgentsPageStore";
import { TravelDataEditorPageStore } from "./pages/TravelDataEditor/TravelDataEditorPageStore";
import { UpcomingTripsPageStore } from "./pages/UpcomingTrips/UpcomingTripsPageStore";
import { UserPageStore } from "./pages/User/UserPageStore";
import { UsersPageStore } from "./pages/Users/UsersPageStore";
import { VehicleModelsPageStore } from "./pages/VehicleModels/VehicleModelsPageStore";
import { VehiclesPageStore } from "./pages/Vehicles/VehiclesPageStore";
import { routes } from "./routes";
import { StoreManager } from "./utils/StoreManager";

export const stores = {
    routing: "routingStore",
    layout: "layoutStore",
    driverLayout: "driverLayoutStore",
    driversCompanyLayout: "driversCompanyLayout",
    history: "historyStore",

    indexPage: "indexPage",
    loginPage: "loginPage",
    changePassword: "changePassword",

    countryGroups: "countryGroups",

    travelDataEditorPage: "travelDataEditorPageStore",
    blacklistedDepartureDatesPage: "blacklistedDepartureDatesPage",

    contentMapPage: "contentMapPage",

    locationPage: "locationPage",
    poolLocationPage: "poolLocationPage",
    locationsPage: "locationsPage",

    routePage: "routePage",
    routesPage: "routesPage",

    landingContentPage: "landingContentPage",
    landingFAQsPage: "landingFAQsPage",

    userPage: "userPage",
    usersPage: "usersPage",
    customerPage: "customerPage",
    customersPage: "customersPage",
    customersFeedbacksPage: "customersFeedbacksPage",

    orderPage: "orderPage",
    ordersPage: "ordersPage",
    ordersClaimingPage: "ordersClaimingPage",

    countryPage: "countryPage",
    countriesPage: "countriesPage",

    paymentsPage: "paymentsPage",

    tagsPage: "tagsPage",

    driversPage: "driversPage",
    driversCompaniesPage: "driversCompaniesPage",
    driversMapPage: "driversMapPage",
    driversPayoutsPage: "driversPayoutsPage",
    driversAssignationPage: "driversAssignationPage",
    driversBalancesPage: "driversBalancesPage",

    vehicleModelsPage: "vehicleModelsPage",
    documentTypesPage: "documentTypesPage",
    cooperationAgreementPage: "cooperationAgreementPage",

    driversCompanyPage: "driversCompanyPage",
    companyDriversPage: "companyDriversPage",

    driverProfilePage: "driverProfilePage",
    billingInformationPage: "drivingBillingInformationPage",
    vehiclesPage: "vehiclesPage",
    passwordResetPage: "passwordResetPage",
    customerFeedbacksForDriverPage: "customerFeedbacksForDriverPage",
    customerFeedbacksForDriversCompanyPage: "customerFeedbacksForDriversCompanyPage",
    driverAvailabilityPage: "driverAvailabilityPage",
    upcomingTripsPage: "upcomingTripsPage",
    driversCompanyUpcomingTripsPage: "driversCompanyUpcomingTripsPage",
    completedTripsPage: "completedTripsPage",
    declinedAndCancelledTripsPage: "declinedAndCancelledTripsPage",
    driversCompanyDeclinedAndCancelledTripsPage: "driversCompanyDeclinedAndCancelledTripsPage",
    driversAssignationsPage: "driversAssignationsPage",
    ordersAddressCheck: "ordersAddressCheckPageStore",
    ordersDepositCheck: "ordersDepositCheckPageStore",
    travelAgents: "travelAgentsPageStore",
    travelAgent: "travelAgentPageStore",
    hostAgencies: "hostAgenciesPageStore",
    hostAgency: "hostAgencyPageStore",
    currenciesPage: "currenciesPage",
    referralCodesPage: "referralCodesPageStore",
    translationsPage: "TranslationsPage",
    languagesPage: "LanguagesPage",
    exportsPage: "exportsPage",
    apiPartnersPage: "apiPartnersPage",
    apiPartnerPage: "apiPartnerPage",
    partnersPage: "partnersPage",
    partnerPage: "partnerPage",
    refundsPage: "refundsPage",
    driverFaq: "driverFaq",

    emailAttemptsBlock: "emailAttemptsBlock",

    numberingTemplatesPage: "numberingTemplatesPage",
    invoicesPage: "invoicesPage",
    assignationOffers: "assignationOffers",

    poolAvailabilityConfigsPage: "poolAvailabilityConfigsPage",
    poolAvailabilityConfigPage: "poolAvailabilityConfigPage",

    assignationsPage: "assignationsPage",

    cooperationAgreementDialog: "cooperationAgreementDialog",
    cooperationAgreementDynamicFormDialog: "cooperationAgreementDynamicFormDialog",
    flexibleOffers: "flexibleOffers",

    ridesPage: "ridesPage",
    ridePage: "ridePage",

    driverCountryRulesPage: "driverCountryRulesPage",
    driversValidationErrorsPage: "driversValidationErrorsPage",

    notifications: "notifications",
    creditLineStore: "creditLineStore",
};

// history
container.bind(stores.routing).toConstantValue(new (injectable()(RoutingStore))());
container
    .bind(stores.history)
    .toConstantValue(syncHistoryWithStore(hashHistory, container.get<RoutingStore>(stores.routing)));

container.bind(stores.layout).toConstantValue(new StoreManager(LayoutStore));
container.bind(stores.driversCompanyLayout).toConstantValue(new StoreManager(DriversCompanyLayoutStore));
container.bind(stores.indexPage).toConstantValue(new StoreManager(IndexPageStore));
container.bind(stores.contentMapPage).toConstantValue(new StoreManager(ContentMapPageStore));
container.bind(stores.driverLayout).toConstantValue(new StoreManager(DriverLayoutStore));
container.bind(stores.travelDataEditorPage).toConstantValue(new StoreManager(TravelDataEditorPageStore));
container.bind(stores.loginPage).toConstantValue(new StoreManager(LoginPageStore));
container.bind(stores.locationPage).toConstantValue(new StoreManager(LocationPageStore));
container.bind(stores.poolLocationPage).toConstantValue(new StoreManager(PoolLocationPageStore));
container.bind(stores.locationsPage).toConstantValue(new StoreManager(LocationsPageStore));
container.bind(stores.routePage).toConstantValue(new StoreManager(RoutePageStore));
container.bind(stores.landingContentPage).toConstantValue(new StoreManager(LandingContentPageStore));
container.bind(stores.landingFAQsPage).toConstantValue(new StoreManager(LandingFAQsPageStore));
container.bind(stores.routesPage).toConstantValue(new StoreManager(RoutesPageStore));
container.bind(stores.countriesPage).toConstantValue(new StoreManager(CountriesPageStore));
container.bind(stores.userPage).toConstantValue(new StoreManager(UserPageStore));
container.bind(stores.usersPage).toConstantValue(new StoreManager(UsersPageStore));
container.bind(stores.customerPage).toConstantValue(new StoreManager(CustomerPageStore));
container.bind(stores.driversCompanyPage).toConstantValue(new StoreManager(DriversCompanyStore));
container.bind(stores.driverProfilePage).toConstantValue(new StoreManager(DriverProfileStore));
container.bind(stores.billingInformationPage).toConstantValue(new StoreManager(BillingInformationPageStore));
container.bind(stores.vehiclesPage).toConstantValue(new StoreManager(VehiclesPageStore));
container.bind(stores.passwordResetPage).toConstantValue(new StoreManager(PasswordResetPageStore));
container.bind(stores.driversPayoutsPage).toConstantValue(new StoreManager(DriversPayoutsPageStore));
container.bind(stores.ordersPage).toConstantValue(new StoreManager(OrdersPageStore));
container.bind(stores.countryPage).toConstantValue(new StoreManager(CountryPageStore));
container.bind(stores.driverAvailabilityPage).toConstantValue(new StoreManager(DriverAvailabilityPageStore));
container.bind(stores.changePassword).toConstantValue(new StoreManager(ChangePasswordStore));
container.bind(stores.paymentsPage).toConstantValue(new StoreManager(PaymentsPageStore));
container.bind(stores.driversPage).toConstantValue(new StoreManager(DriversPageStore));
container.bind(stores.driversCompaniesPage).toConstantValue(new StoreManager(DriversCompaniesPageStore));
container.bind(stores.orderPage).toConstantValue(new StoreManager(OrderPageStore));
container.bind(stores.driversAssignationPage).toConstantValue(new StoreManager(DriversAssignationPageStore));
container.bind(stores.driversAssignationsPage).toConstantValue(new StoreManager(DriversAssignationsPageStore));
container.bind(stores.driversBalancesPage).toConstantValue(new StoreManager(DriversBalancesPageStore));
container
    .bind(stores.blacklistedDepartureDatesPage)
    .toConstantValue(new StoreManager(BlacklistedDepartureDatesPageStore));
container
    .bind(stores.cooperationAgreementDynamicFormDialog)
    .toConstantValue(new StoreManager(CooperationAgreementDynamicFormStore));
container.bind(stores.upcomingTripsPage).toConstantValue(new StoreManager(UpcomingTripsPageStore));
container
    .bind(stores.driversCompanyUpcomingTripsPage)
    .toConstantValue(new StoreManager(DriversCompanyUpcomingTripsPageStore));
container.bind(stores.completedTripsPage).toConstantValue(new StoreManager(CompletedTripsPageStore));
container
    .bind(stores.declinedAndCancelledTripsPage)
    .toConstantValue(new StoreManager(DeclinedAndCancelledTripsPageStore));
container
    .bind(stores.driversCompanyDeclinedAndCancelledTripsPage)
    .toConstantValue(new StoreManager(DriversCompanyDeclinedAndCancelledTripsPageStore));
container.bind(stores.customersPage).toConstantValue(new StoreManager(CustomersPageStore));
container.bind(stores.documentTypesPage).toConstantValue(new StoreManager(DocumentTypesPageStore));
container.bind(stores.driversMapPage).toConstantValue(new StoreManager(DriversMapPageStore));
container.bind(stores.vehicleModelsPage).toConstantValue(new StoreManager(VehicleModelsPageStore));
container.bind(stores.ordersAddressCheck).toConstantValue(new StoreManager(OrdersAddressCheckPageStore));
container.bind(stores.ordersDepositCheck).toConstantValue(new StoreManager(OrdersDepositCheckPageStore));
container.bind(stores.customersFeedbacksPage).toConstantValue(new StoreManager(CustomersFeedbacksPageStore));
container.bind(stores.tagsPage).toConstantValue(new StoreManager(TagsPageStore));
container.bind(stores.companyDriversPage).toConstantValue(new StoreManager(CompanyDriversPageStore));
container.bind(stores.travelAgents).toConstantValue(new StoreManager(TravelAgentsPageStore));
container.bind(stores.travelAgent).toConstantValue(new StoreManager(TravelAgentPageStore));
container
    .bind(stores.customerFeedbacksForDriversCompanyPage)
    .toConstantValue(new StoreManager(CustomerFeedbacksForDriversCompanyPageStore));
container
    .bind(stores.customerFeedbacksForDriverPage)
    .toConstantValue(new StoreManager(CustomerFeedbacksForDriverPageStore));
container.bind(stores.currenciesPage).toConstantValue(new StoreManager(CurrenciesPageStore));
container.bind(stores.referralCodesPage).toConstantValue(new StoreManager(ReferralCodesPageStore));
container.bind(stores.exportsPage).toConstantValue(new StoreManager(ExportsPageStore));
container.bind(stores.apiPartnersPage).toConstantValue(new StoreManager(ApiPartnersPageStore));
container.bind(stores.apiPartnerPage).toConstantValue(new StoreManager(ApiPartnerPageStore));
container.bind(stores.partnersPage).toConstantValue(new StoreManager(PartnersStore));
container.bind(stores.partnerPage).toConstantValue(new StoreManager(PartnerStore));
container.bind(stores.translationsPage).toConstantValue(new StoreManager(TranslationsPageStore));
container.bind(stores.languagesPage).toConstantValue(new StoreManager(LanguagesPageStore));
container.bind(stores.refundsPage).toConstantValue(new StoreManager(RefundsPageStore));
container.bind(stores.emailAttemptsBlock).toConstantValue(new StoreManager(EmailAttemptsStore));
container.bind(stores.numberingTemplatesPage).toConstantValue(new StoreManager(NumberingTemplatesPageStore));
container.bind(stores.invoicesPage).toConstantValue(new StoreManager(InvoicesPageStore));
container.bind(stores.assignationsPage).toConstantValue(new StoreManager(AssignationsPageStore));
container.bind(stores.poolAvailabilityConfigsPage).toConstantValue(new StoreManager(PoolAvailabilityConfigsPageStore));
container.bind(stores.poolAvailabilityConfigPage).toConstantValue(new StoreManager(PoolAvailabilityConfigPageStore));
container.bind(stores.flexibleOffers).toConstantValue(new StoreManager(FlexibleOffersPageStore));
container.bind(stores.ridesPage).toConstantValue(new StoreManager(RidesPageStore));
container.bind(stores.ridePage).toConstantValue(new StoreManager(RidePageStore));
container.bind(stores.driverCountryRulesPage).toConstantValue(new StoreManager(DriverCountryRulesPageStore));
container.bind(stores.countryGroups).toConstantValue(new StoreManager(CountryGroupsStore));
container.bind(stores.notifications).toConstantValue(new StoreManager(NotificationsStore));
container.bind(stores.cooperationAgreementPage).toConstantValue(new StoreManager(CooperationAgreementPageStore));
container.bind(stores.hostAgencies).toConstantValue(new StoreManager(HostAgenciesPageStore));
container.bind(stores.hostAgency).toConstantValue(new StoreManager(HostAgencyPageStore));
container.bind(stores.creditLineStore).toConstantValue(new StoreManager(CreditLineStore));
container.bind(stores.driverFaq).toConstantValue(new StoreManager(DriversFaqPageStore));

export { container, lazyInject, routes, StoreManager, inject, RoutingStore };
