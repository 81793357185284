import { transformPhoneNumberToNormalizedForm } from "@daytrip/utils";
import { Transform } from "class-transformer";
import {
    IsDefined,
    IsString,
    IsUUID,
    IsEmail,
    IsBoolean,
    IsNumber,
    IsNotEmpty,
    Length,
    Min,
    Max,
} from "class-validator";
import { v4 as uuid } from "uuid";

export class ApiPartnerModel {
    @IsUUID("4")
    @IsDefined()
    public _id: string = uuid();

    @IsString()
    @IsDefined()
    @IsNotEmpty()
    @Transform((value) => value && value.trim())
    public name: string;

    @IsEmail()
    @IsDefined()
    public email: string;

    @IsString()
    @IsDefined()
    @IsNotEmpty()
    public apiKey: string;

    @Length(4, 18)
    @IsString()
    @IsDefined()
    @Transform((value) => transformPhoneNumberToNormalizedForm(value))
    public phoneNumber: string;

    @IsUUID("4")
    @IsDefined()
    public countryId: string;

    @IsBoolean()
    @IsDefined()
    public emailsEnabled: boolean = false;

    @IsNumber()
    @IsDefined()
    @Min(0)
    @Max(100)
    public discountPercentage: number;

    @IsUUID("4")
    @IsDefined()
    public ownerId: string;
}
